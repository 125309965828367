import React, { useState } from "react";
import {
  Table,
  Input,
  Button,
  message,
  Tooltip,
  ColorPicker,
  Select,
  Spin,
} from "antd";
import { CiEdit } from "react-icons/ci";
import { TfiSave } from "react-icons/tfi";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { postCustomizaciones, setCustomizaciones } from "../Redux/actions";

const { Option } = Select;

const EditableTable = () => {
  const customizaciones = useSelector((state) => state.customizaciones);
  const cliente = useSelector((state) => state.clientes);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(customizaciones);
  const dispatch = useDispatch();

  const success = (updatedData, key) => {
    messageApi.open({
      type: "success",
      content: `${
        updatedData.find((item) => item.key === key).fieldName
      } guardado`,
    });
  };

  const handleFieldValueChange = (value, key) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, fieldValue: value } : item
      )
    );
  };

  const handleEdit = (key) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, editable: !item.editable } : item
      )
    );
  };

  const handleSave = async (key) => {
    const updatedData = data.map((item) =>
    item.key === key ? { ...item, editable: false } : item
  );

  setData(updatedData);
  showLoader();
  setTimeout(() => {
    success(updatedData, key);
  }, 1500);
    
    // Crear un objeto de customizaciones basado en `updatedData`
    const customizationsToSend = updatedData.reduce((acc, curr) => {
      switch (curr.fieldName) {
        case "Nombre de la Empresa":
          acc.nombreEmpresa = curr.fieldValue;
          break;
        case "Color Principal":
          acc.colorPrincipal = curr.fieldValue;
          break;
        case "Color Secundario":
          acc.colorSecundario = curr.fieldValue;
          break;
        case "Tipo de Letra":
          acc.tipoLetra = curr.fieldValue;
          break;
        case "URL del Logo":
          acc.logoImg = curr.fieldValue;
          break;
        default:
          break;
      }
      return acc;
    }, {});
  
    // Asegúrate de agregar el clienteId desde el estado de Redux o cualquier otra fuente válida
    customizationsToSend.clienteId = cliente.id_cliente; // Asegúrate de tener el `id` correcto del cliente aquí
  
    // Ahora dispatcha la acción con el objeto completo
    setTimeout(() => {
      dispatch(postCustomizaciones(customizationsToSend)); // Asumiendo que postCustomizaciones puede manejar este objeto directamente
    }, 2000);
    setTimeout(() => {
      const editedItem = updatedData.find((item) => item.key === key);
      if (editedItem) {
        dispatch(
          setCustomizaciones(editedItem.fieldName, editedItem.fieldValue)
        );
      }
    }, 2000);
  };
  

  const handleColorChange = (color, key) => {
    const hexColor = color?.toHexString();
    setData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, fieldValue: hexColor } : item
      )
    );
  };

  const handleFontChange = (fontName, key) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, fieldValue: fontName } : item
      )
    );
  };

  const columns = [
    {
      title: "Campo",
      dataIndex: "fieldName",
      key: "fieldName",
    },
    {
      title: "Valor",
      dataIndex: "fieldValue",
      key: "fieldValue",
      render: (text, record) => {
        if (
          record.editable &&
          (record.fieldName === "Color Principal" ||
            record.fieldName === "Color Secundario")
        ) {
          return (
            <ColorPicker
              value={text}
              onChange={(color) => handleColorChange(color, record.key)}
            />
          );
        } else if (record.editable && record.fieldName === "Tipo de Letra") {
          return (
            <Select
              value={text}
              style={{ width: "100%" }}
              onChange={(fontName) => handleFontChange(fontName, record.key)}
            >
              <Option value="Amatic SC" style={{ fontFamily: "Amatic SC" }}>
                Amatic SC
              </Option>
              <Option value="Open Sans" style={{ fontFamily: "Open Sans" }}>
                Open Sans
              </Option>
              <Option
                value="Dancing Script"
                style={{ fontFamily: "Dancing Script" }}
              >
                Dancing Script
              </Option>
              <Option
                value="Gloria Hallelujah"
                style={{ fontFamily: "Gloria Hallelujah" }}
              >
                Gloria Hallelujah
              </Option>
              <Option value="Grape Nuts" style={{ fontFamily: "Grape Nuts" }}>
                Grape Nuts
              </Option>
              <Option value="Mate" style={{ fontFamily: "Mate" }}>
                Mate
              </Option>
              <Option
                value="Montserrat Alternates"
                style={{ fontFamily: "Montserrat Alternates" }}
              >
                Montserrat Alternates
              </Option>
              <Option
                value="Playfair Display"
                style={{ fontFamily: "Playfair Display" }}
              >
                Playfair Display
              </Option>
              <Option value="Roboto" style={{ fontFamily: "Roboto" }}>
                Roboto
              </Option>
              <Option
                value="Shadows Into Light"
                style={{ fontFamily: "Shadows Into Light" }}
              >
                Shadows Into Light
              </Option>
              <Option value="Montserrat" style={{ fontFamily: "Montserrat" }}>
                Montserrat
              </Option>
            </Select>
          );
        } else if (record.editable) {
          return (
            <Input
              value={text}
              onChange={(e) =>
                handleFieldValueChange(e.target.value, record.key)
              }
            />
          );
        } else if (
          record.fieldName === "Color Principal" ||
          record.fieldName === "Color Secundario"
        ) {
          return <ColorPicker value={text} disabled />;
        } else if (record.fieldName === "URL del Logo") {
          return (
            <a
              href={text}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                maxWidth: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {text}
            </a>
          );
        }
        return text;
      },
    },
    {
      title: "Acción",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <span>
          {record.editable ? (
            <Tooltip title="Guardar">
              <Button
                className="btn-guardar btn-action"
                shape="circle"
                onClick={() => handleSave(record.key)}
                icon={<TfiSave />}
              />
            </Tooltip>
          ) : cliente?.plan === "emprendedor" ? (
            <Tooltip title="Subir de plan para poder editar" disabled>
              <Button
                className="btn-action"
                type="primary"
                shape="circle"
                icon={<CiEdit />}
                disabled
              />
            </Tooltip>
          ) : (
            <Tooltip title="Editar">
              <Button
               className="btn-action"
                type="primary"
                shape="circle"
                onClick={() => handleEdit(record.key)}
                icon={<CiEdit />}
              />
            </Tooltip>
          )}
        </span>
      ),
    },
  ];

  const showLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      {contextHolder}
      <Table
        dataSource={data}
        columns={columns}
        bordered
        pagination={false}
        scroll={{ x: "max-content" }}
        disabled
      />
      <Spin spinning={loading} fullscreen />
    </>
  );
};

export default EditableTable;
