// actionTypes.js
export const SET_MENSAJES_DATA = 'SET_MENSAJES_DATA';
export const SET_CUALIFICADOS_DATA = 'SET_CUALIFICADOS_DATA';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_PUESTOS = 'SET_PUESTOS';
export const SET_CONEXIONES_DATA = 'SET_CONEXIONES_DATA';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_INVITACIONES_DATA = 'SET_INVITACIONES_DATA';
export const LOAD_LOCALSTORAGE_DATA = 'LOAD_LOCALSTORAGE_DATA';
export const ACTUALIZAR_POSICIONES = 'ACTUALIZAR_POSICIONES';
export const TRANSFER_OK = 'TRANSFER_OK';
export const SET_MES = 'SET_MES';
export const NOMBRE_CUENTA = 'NOMBRE_CUENTA'
export const SET_MENSAJES_NEW = 'SET_MENSAJES_NEW'
export const NOMBRE_CUENTA_BORRAR = "NOMBRE_CUENTA_BORRAR";
export const SET_CUSTOMIZACIONES = 'SET_CUSTOMIZACIONES'
export const SET_SEMANAS = "SET_SEMANAS"
export const SET_CONEXIONES_ALL = "SET_CONEXIONES_ALL"
export const SET_FECHAS_FILTROS = "SET_FECHAS_FILTROS"
export const SET_MENSAJES_ALL = "SET_MENSAJES_ALL"

// get clientes
export const FETCH_DATA_START = 'FETCH_DATA_START'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'
export const FETCH_DATA_END = 'FETCH_DATA_END'
export const GET_CLIENTES = 'GET_CLIENTES'
export const POST_CLIENTE = 'POST_CLIENTE'
export const PATCH_CLIENTES = 'PATCH_CLIENTES'

//logout
export const LOGOUT_USER = "LOGOUT_USER"

//usuario
export const EDIT_USER = "EDIT_USER" // usuario perfil
export const ADD_USER = "ADD_USER"
export const EDIT_USUARIO = 'EDIT_USUARIO' //usuario de cada cliente

//funcionalidades
export const ADD_FUNCIONALIDAD = "ADD_FUNCIONALIDAD"
export const GET_FUNCIONALIDADES = "GET_FUNCIONALIDADES"
export const PATCH_FUNCIONALIDAD = "PATCH_FUNCIONALIDAD"

//customizaciones
export const POST_CUSTOMIZACIONES = 'POST_CUSTOMIZACIONES'

//seguimiento
export const ADD_SEGUIMIENTO = "ADD_SEGUIMIENTO"